module.exports = [
    {
        social: [
            {
                service: 'facebook',
                href: 'https://www.facebook.com/Reindeertaxi/',
            },
            {
                service: 'instagram',
                href: 'https://www.instagram.com/reindeertaxi',
            },
        ],
    },
    
]