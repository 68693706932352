module.exports = [
  {
    lang: 'fi',
    path: '/',
    logo: { href: '/', title: 'Reindeer Taxi' },
    nav: [
        { href: `https://porotaksi.fi/#pricing-table`, title: 'Hinnasto'},
        { href: '/about', title: 'Palvelut'},
        { href: '/blog', title: 'Blogi'},
        { href: '/contact', title: 'Yhteystiedot'},
    ],
  },
  {
    lang: 'en',
    path: '/en/',
    logo: { href: '/en/', title: 'Reindeer Taxi' },
    nav: [
        { href: `https://porotaksi.fi/en/#pricing-table`, title: 'Pricing'},
        { href: '/en/about', title: 'Services'},
        { href: '/blog', title: 'Blog'},
        { href: '/en/contact', title: 'Contact'},
    ],
  },
]